<template>
<div class="app-main__inner">
  <div class="row">
    <div class="col-md-12">
        <div class="app-page-title dashboard-title">
            <div class="page-title-wrapper">
                <div class="page-title-heading">
                    <div class="page-title-icon">
                        <i class="pe-7s-albums icon-gradient bg-happy-itmeo">
                        </i>
                    </div>
                    <div>User Messages</div>
                </div>
            </div>
        </div>
    </div>
  </div>
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-3">
              <div class="card">
                <div class="card-header text-white bg-danger">
                  List of all users messages
                </div>
                <div class="card-body">
            <div class="overflow-auto">
      <b-row>

      <b-col lg="6" class="my-1 mb-2">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button variant="outline-primary" :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Filter On"
          description="Leave all unchecked to filter on all data"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="filterOn"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
          >
            <b-form-checkbox value="first_name">User Name</b-form-checkbox>
            <b-form-checkbox value="email_id">Email</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

    </b-row>
                <b-table striped hover :items="allUsers" :per-page="perPage"
                :current-page="currentPage" :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                >
                <template #cell(user_id)="data">
                   <b-button variant="outline-info" @click="responseToUser(data.item.user_id)">
                     Response
                   </b-button>
                  </template>
                  <template  #cell(responseFlag)="data">
                    <div class="text-center"><b-badge class="successBg" v-if="data.item.responseFlag==1" variant="success"> 1</b-badge>
                    <b-badge class="dangerBg" v-else variant="danger"> 0</b-badge>
                    </div>
                  </template>
                 <template #cell(username)="data">
                            <i>{{ data.item.username }}</i>
                          </template>
                          <template #cell(email_id)="data">
                            <i>{{ data.item.email_id }}</i>
                          </template>
                          <template #cell(message)="data">
                            <i>{{ data.item.message }}</i>
                          </template>
                          <template  #cell(message_date)="data">
                            <i>{{changeDate(data.item.message_date, "fullDate")}}</i>
                          </template> 
                </b-table>
                <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                ></b-pagination>
            </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <b-modal
      id="modal-user-message"
      ref="modalUserMessage"
      :title="popupTitle"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      okTitle="Send Response"
      size="xl"
      :ok-disabled="sendResponseWait"
    >
      <form v-if="messageDetail!=null" ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
        id="userEmail"
        label="User Email"
        label-for="userEmail"
      >
        <b-form-input id="userEmail" v-model="messageDetail.email_id" trim disabled></b-form-input>
      </b-form-group>
      <b-form-group
        id="userName"
        label="User Name"
        label-for="userName"
      >
        <b-form-input id="userName" v-model="messageDetail.username" trim disabled></b-form-input>
      </b-form-group>
      <b-form-group
        id="userMessage"
        label="User Message"
        label-for="userMessage">
        <b-form-textarea id="userMessage" v-model="messageDetail.message" trim disabled
        rows="3"
        max-rows="6"></b-form-textarea>
      </b-form-group>
      <b-form-group
        id="responseTitle"
        label="Response Title"
        label-for="responseTitle"
      >
        <b-form-input id="responseTitle" v-model="responseTitle" trim></b-form-input>
      </b-form-group>
      <b-form-group
        id="adminResponse"
        label="Response"
        label-for="adminResponse">
        <b-form-textarea style="min-height: 200px;" id="adminResponse" v-model="adminResponse" trim placeholder="Enter response..."
        rows="3"
        max-rows="6"></b-form-textarea>
      </b-form-group>
      </form>
    </b-modal>
</div>
</template>
<script>
import moment from "moment";
import { mapActions,mapGetters } from 'vuex'
export default {
    props:['usersmessages'],
    data(){
        return{
        perPage: 10,
        sortDirection: 'asc',
        currentPage: 1,
        popupTitle:'Response to ',
        minDate:'',
        maxDate:'',
        totalRows: 1,
        filter: null,
        filterOn: [],
        adminResponse:'',
        responseTitle:'',
        sendResponseWait:true,
        fields: [
          {
            key: 'user_id',
            label: '',
            sortable: false,
          },
          {
            key: 'responseFlag',
            label: 'Responed',
            sortable: false,
          },
          {
            key: 'username',
            label: 'User',
            sortable: true,
          },
          {
            key: 'email_id',
            label: 'Email',
            sortable: true,
          },
          {
            key: 'message',
            label: 'Message',
            sortable: true,
          },
          {
            key: 'message_date',
            label: 'Date',
            sortable: true,
          }
        ],
        allUsers:this.usersmessages,
        messageDetail:null
        }
    },
    computed: {
      rows() {
        if(this.allUsers==null) {//console.log('returning 0')
        return  0}
        else{
          return this.allUsers.length
        }
        
      }
    },
  watch: {
    adminResponse: function () {
      this.sendResponseWait=(this.adminResponse=='' | this.responseTitle=='')?true:false
    },
    responseTitle: function () {
      this.sendResponseWait=(this.adminResponse=='' | this.responseTitle=='')?true:false
    }
  },
    methods:{
      ...mapActions({
            saveData: 'speakmodule/saveAnswer',
        }),
        ...mapGetters({
            dataResponse: 'speakmodule/AnswerResponse',
        }),
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      resetModal(){

      },
      handleOk(){
        if(this.adminResponse=='' | this.responseTitle==''){
          return this.$alert('Before submit you need to enter your response.')
        }
        var data={
          user_id:this.messageDetail.user_id,
          adminResponse:this.adminResponse,
          userEmail:this.messageDetail.email_id,
          message_id:this.messageDetail.message_id,
          adminResponseTitle:this.responseTitle,
          userName:this.messageDetail.username,
          url:'users/adminResponse'
        }
        this.saveData(data).then(()=>{
          const response=this.dataResponse()
          if(response.success==1){
            this.$alert('Your message is deliverd to respect user.')
          }

        })
        //console.log(data)
        this.$refs['modalUserMessage'].hide()
      },
      responseToUser(userId){
        //console.log(userId)
        this.adminResponse=''
        this.messageDetail=this.allUsers.filter((item)=>{
          return item.user_id==userId
        })[0]
        this.popupTitle+=this.messageDetail.username
        //console.log(this.messageDetail)
        this.$refs['modalUserMessage'].show()
      },
      minMaxDate() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      // 15th two months prior
      const minDate = new Date(today)
      //minDate.setMonth(minDate.getMonth() - 2)
      minDate.setDate(minDate.getDate()+1)
      // 15th in two months
      const maxDate = new Date(today)
      maxDate.setMonth(maxDate.getMonth() + 12)
      //maxDate.setDate(15)
        this.minDate=minDate
        this.maxDate=maxDate
    },
     changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("Do MMMM YYYY");
          }
          else if(dateType === "shortDate"){
             return moment(date1).format("YYYY-MM-D");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
      // fetchUsersMessages(){
      // var url='users/getallUserMessages'
      //  this.fetchUsersMessages(url).then(()=>{
      //     const response=this.GetUsersMessages()
      //     this.allUsers=response.data
      //     ////console.log(this.allUsers)
      // });
      // }
    }
}
</script>
<style scoped>
.successBg, .dangerBg
{
      color: #32cd32!important;
    border-radius: 50%;
    /* width: 15px; */
    padding: 0px;
    height: 14px;
    min-width: 14px;
}
.dangerBg
{
  color: #d92550!important;
}
</style>